import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { Auth, Storage } from 'aws-amplify';
import { Button, Heading, View, Flex, } from '@aws-amplify/ui-react';
import { useHistory } from "react-router-dom";

const Homepage = ({ signOut }) => {
  const backgroundImage = "url('./bgimg.jpg')";
  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }


  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setProfilePicture(file);
  };

  useEffect(() => {
  const fetchProfilePicture = async () => {
    try {
      const profilePictureKey = `profile_pictures/${username}.PNG`;
      const url = await Storage.get(profilePictureKey);
      setProfilePicture(url);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };


    fetchProfilePicture();
    getUsername();
    setIsMobile(window.innerWidth <= 1000);
  }, [username]);

  async function getUsername() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUsername(user.username);
      setEmail(user.attributes.email); // Assuming email is one of the attributes you want to display
    } catch (error) {
      console.log('error getting user info: ', error);
    }
  }

  const uploadProfilePicture = async () => {
    try {
      if (profilePicture) {
        const fileExtension = profilePicture.name.split('.').pop(); // Get file extension
        const profilePictureKey = `profile_pictures/${username}.${fileExtension}`;
        console.log(profilePictureKey);
        await Storage.put(profilePictureKey, profilePicture);
        console.log('Profile picture uploaded successfully!');
      } else {
        console.log('No profile picture selected.');
      }
    } catch (error) {
      console.error('Error uploading profile picture:', error);
    }
  };

  return (
    <View className="Homepage" style={{ position: 'relative', height: '100vh', backgroundImage: 'url(./bgimg.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ width: '30%', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'left' }}>
        <Heading style={{ color: '#fff', fontSize: '1.5em', marginBottom: '20px' }} level={6}>Welcome, {username} to fantuzz.org!</Heading>
        <p style={{ color: '#fff', fontSize: '14px' }}>A place to share photo albums, connect directly with users, and all hosted securely on AWS.</p>
      </div>
      <div style={{ width: '70%', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', backgroundColor: 'rgba(0, 0, 0, 0.4)', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
        <Heading style={{ color: '#fff', fontSize: '1.5em', marginBottom: '10px' }} level={6}>My Profile</Heading>
      <div style={{ marginBottom: '20px' }}>
        <Heading style={{ color: '#fff', fontSize: '0.75em', marginBottom: '20px' }} level={6}>Change Profile Picture</Heading>
        <input type="file" accept="image/PNG" onChange={handleFileChange} />
        <Button onClick={uploadProfilePicture}>Upload</Button>
      </div>
      <img
        src={profilePicture}
        alt="Profile"
        style={{
          width: '10vw', // Set the width of the image
          height: '10vw', // Set the height of the image
          objectFit: 'cover', // Maintain aspect ratio and cover entire container
          borderRadius: '50%',
          marginBottom: '20px'
        }}
      />   
       <p style={{ color: '#fff', fontSize: '16px', marginBottom: '20px' }}>Username: {username}</p>
        <p style={{ color: '#fff', fontSize: '16px', marginBottom: '20px' }}>Authenticated Email: {email}</p>
      </div>
    </View>
  );

};

export default Homepage;