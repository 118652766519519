import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { Auth } from 'aws-amplify';
import { Button, Heading, View, Flex, } from '@aws-amplify/ui-react';

const NavBar = ({ signOut }) => {

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const [username, setUsername] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    getUsername();
    setIsMobile(window.innerWidth <= 1000);

  }, []);

  async function getUsername() {
    const { username } = await Auth.currentAuthenticatedUser();
    setUsername(username);
  } 

return(
  <View style={{ background: 'linear-gradient(to bottom left, #9198e5, #0C647B)', padding:'20px', display: 'flex', alignItems: 'center' }}>
    {!isMobile &&      <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ width: '10vh', height: '10vh', borderRadius: '50%', background: 'radial-gradient(#9198e5, #0C647B)', display: 'flex', justifyContent: 'center', alignItems: 'center', animation: 'growShrink 2s infinite alternate-reverse' }}>
        <span style={{ color: '#fff', fontStyle: 'italic', fontSize: '2.5em' }}>Fnet</span>
      </div>
    </div>}
    <Heading style={{ color: '#fff', paddingLeft: '10%'}} level={2}>Fantuzz.o</Heading><Heading style={{ color: '#999'}} level={2}>rg</Heading>
     <Heading style={{ color: '#fff', marginLeft: '10%', marginTop: '10px', fontSize: '0.85em' }} level={6}>Connect Privately with a Geographically Distributed Family</Heading>
        <Flex direction="column" justifyContent="center" alignItems="center" style={{ marginLeft: 'auto' }}>
          <Heading style={{ color: '#fff', display: 'inline-block', marginTop:10, fontSize:'14px'}} level={6}>{username} is signed in. </Heading>
          <Button onClick={() => handleSignOut()} style={{ marginLeft:'0%', marginTop:1 }}>Sign Out</Button>
        </Flex>
  </View>
);

}

export default NavBar;
