import React, { useState, useEffect } from "react";
import { API, Storage, Auth } from 'aws-amplify';
import { createNote as createNoteMutation, deleteNote as deleteNoteMutation,} from "./graphql/mutations";
import { listNotes } from "./graphql/queries";
import { Tabs, TabItem, Button, Flex, Heading, Image, Text, TextField, View, withAuthenticator, } from '@aws-amplify/ui-react';
import { Input } from "@chakra-ui/react";


const Exchange = ({ createNote }) => {

  const [notes, setNotes] = useState([]);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [allNotes, setAllNotes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [titleQuery, setTitleSearchQuery] = useState("");
  const [descQuery, setDescSearchQuery] = useState("");
  const [isMobile, setIsMobile] = useState(false);

/*
async function getImages({ id, name, description }) {
  const { username } = await Auth.currentAuthenticatedUser();
  const imagesInDescription = await Storage.list(name + '_' + username + '/' + description + '/');
  const imagesInDescription2 = imagesInDescription.results;
  const imageUrls = await Promise.all(
    imagesInDescription2.map(async (image) => {
      const url = await Storage.get(image.key);
      return url;
    })
  );
  imageUrls.forEach((url) => {
    window.open(url);
  });
};
*/

  async function getRecipientProfilePicture(owner) {
    try {
      const recipientProfilePictureKey = `profile_pictures/${owner}.PNG`;
      const recipientProfilePictureUrl = await Storage.get(recipientProfilePictureKey);
      return recipientProfilePictureUrl;
    } catch (error) {
      console.error("Error fetching recipient's profile picture:", error);
      return null;
    }
  }

  async function getImages ( {id , name, description, owner}) {
        const { username } = await Auth.currentAuthenticatedUser();
        const imagesInDescription = await Storage.list(name+'_'+owner+'/'+description+'/');
        const imagesInDescription2 = imagesInDescription.results
        console.log(imagesInDescription2);
        const imageUrls = await Promise.all(
          imagesInDescription2.map(async (image) => {
            const url = await Storage.get(image.key);
            return url;
          })
        );
        
        const modal = document.createElement("div");
        modal.classList.add("modal");
        modal.style.width = '100%';
        modal.style.position = "fixed";
        modal.style.top = "0";
        modal.style.left = "0";
        const modalContent = document.createElement("div");
        /*modalContent.classList.add("modal-content");*/
        modalContent.style.backgroundColor = "rgba(0, 0, 0, 0.5)"; 
        modalContent.style.width = "100%"; 

        const modalTitle = document.createElement("h2");
        modalTitle.innerHTML = 'Name: ' + name + ' - ' + description;
        modalTitle.style.color = "#fff"; 
        modalTitle.style.position = "fixed"; 
        modalTitle.style.left = "40%";
        modal.appendChild(modalTitle);

        const modalClose = document.createElement("Button");
        modalClose.classList.add("close");
        modalClose.style.position = "fixed"; 
        modalClose.innerHTML = "Close Album &times;";

        modalClose.onclick = function () {
          document.body.removeChild(modal);
        };

        modalContent.appendChild(modalClose);

        imageUrls.forEach((url) => {
          const img = document.createElement("img");
          img.src = url;
          img.style.width = "50%";
          img.style.height = "auto"; 
          img.style.padding = '10px'
          img.style.objectFit = "cover";
          img.style.display = "block"; // Center the image horizontally
          img.style.margin = "0 auto"; // Center the image vertically
          modalContent.appendChild(img);
        });

        modal.appendChild(modalContent);
        document.body.appendChild(modal);
      }


  useEffect(() => {
    fetchNotes2();
    setIsMobile(window.innerWidth <= 1000);
    //setIsMobile(true);
  }, []);

  const filteredNotes = allNotes.filter((note) =>
    (note.owner.toLowerCase().includes(searchQuery.toLowerCase()) &&
    note.description.toLowerCase().includes(descQuery.toLowerCase()) &&
    note.name.toLowerCase().includes(titleQuery.toLowerCase()))
  );


  async function fetchNotes2() {
    const apiData = await API.graphql({ query: listNotes });
    const notesFromAPI = apiData.data.listNotes.items;
    await Promise.all(
      notesFromAPI.map(async (note) => {
          if (note.image) {
            const url = await Storage.get(note.name+'_'+note.owner+'/'+note.name+'_'+note.owner);
            note.image = url;
          }
        note.recipientProfilePictureUrl = await getRecipientProfilePicture(note.owner);
        return note;
      })
    );
  const notesWithImage = notesFromAPI/*.filter(note => note.image)*/;
  setAllNotes(notesWithImage);
  }

function chunk(arr, size) {
  const chunks = [];
  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }
  return chunks;
}

return (
<React.Fragment>
<br />
  <Heading style={{ top: '1px' }} level={5}>
    Search the Albums
  </Heading>{' '}
  <br />
    <Flex
      style={{ border: "solid 2px #0C647B", padding: "30px", backgroundColor:'#E4ECF2' }}
      direction="row"
      justifyContent="center"
      alignItems="center"
      margin="0 auto"
    >
  <Input
    placeholder="Search by User"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
  /> 
  <Input
    placeholder="Search by Title"
    value={titleQuery}
    onChange={(e) => setTitleSearchQuery(e.target.value)}
  /> 
  <Input
    placeholder="Search by Description"
    value={descQuery}
    onChange={(e) => setDescSearchQuery(e.target.value)}
  /> 
  </Flex>
  <br />
    <Flex
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      margin="0 auto"
    >
      {chunk(filteredNotes, isMobile ? 1 : 4).map((row) => (
        <Flex
          key={row[0].id || row[0].name}
          justifyContent="space-evenly"
          alignItems="center"
          flexWrap="wrap"
          margin="20px 0"
        >
          {row.map((note) => (
            <Flex
              className="card"
              key={note.id || note.name}
              direction="column"
              style={{
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.9)",
                borderRadius: "10px",
                padding: "20px",
                height:'500px',
                width:'300px',
                backgroundColor: "#E4ECF2 ",
                margin: "0 20px",
              }}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Text as="strong" fontWeight={700}>
                {note.name}
              </Text>
              <Text as="strong" fontWeight={300} style={{fontSize: '0.75em'}}>
               posted by {note.owner}
              </Text>
              {!isMobile && <Text as="span">{note.description}</Text>}
            {note.image && (
              <div className="image-container">
                <Image style = {{objectFit: 'cover'}} 
                src={note.image} />
              </div>
            )}

          <Image
            style={{
              width: '9vh', // Set a fixed width for the image
              height: '9vh', // Set a fixed height for the image
              objectFit: 'cover', // Maintain aspect ratio and cover entire container
              borderRadius: '50%' // Make it a circle
             }}
            src={note.recipientProfilePictureUrl}
          />
      <Button onClick={() => getImages(note)}>
        Open Album
      </Button>
            </Flex>
          ))}
        </Flex>
      ))}
    </Flex>
</React.Fragment>
);

};

export default Exchange;
