import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInbox, faImages, faPeopleGroup, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ isCollapsed, toggleCollapse }) => {
  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <button style={{cursor:"pointer", backgroundColor:'transparent', color:'#fff', border: 'none'}} className="collapse-btn" onClick={toggleCollapse}>
        <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft}/>
      </button>
      <Link to="/" className="nav-link">
        {!isCollapsed && (
          <>
            <FontAwesomeIcon icon={faHome} />
            <span> Home</span>
          </>
        )}
        {isCollapsed && <FontAwesomeIcon icon={faHome} />}
      </Link>
      <Link to="/inbox" className="nav-link">
        {!isCollapsed && (
          <>
            <FontAwesomeIcon icon={faInbox} />
            <span> Inbox</span>
          </>
        )}
        {isCollapsed && <FontAwesomeIcon icon={faInbox} />}
      </Link>
      <Link to="/my-albums" className="nav-link">
        {!isCollapsed && (
          <>
            <FontAwesomeIcon icon={faImages} />
            <span> My Albums</span>
          </>
        )}
        {isCollapsed && <FontAwesomeIcon icon={faImages} />}
      </Link>
      <Link to="/family-albums" className="nav-link">
        {!isCollapsed && (
          <>
            <FontAwesomeIcon icon={faPeopleGroup} />
            <span> Family Albums</span>
          </>
        )}
        {isCollapsed && <FontAwesomeIcon icon={faPeopleGroup} />}
      </Link>
    </div>
  );
};

export default Sidebar;
