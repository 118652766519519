import React, { useState, useEffect } from "react";
import { API, Storage, Auth } from 'aws-amplify';
import { createNote as createNoteMutation, deleteNote as deleteNoteMutation,} from "./graphql/mutations";
import { listNotes } from "./graphql/queries";
import { Tabs, TabItem, Button, Flex, Heading, Image, Text, TextField, View, withAuthenticator, } from '@aws-amplify/ui-react';

const NewAlbumForm = ({ createNote }) => {

  const [notes, setNotes] = useState([]);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [allNotes, setAllNotes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    fetchNotes();
    setIsMobile(window.innerWidth <= 1000);
    //setIsMobile(true);
  }, []);

  async function getImages ( {id , name, description}) {
        const { username } = await Auth.currentAuthenticatedUser();
        const imagesInDescription = await Storage.list(name+'_'+username+'/'+description+'/');
        const imagesInDescription2 = imagesInDescription.results
        console.log(imagesInDescription2);
        const imageUrls = await Promise.all(
          imagesInDescription2.map(async (image) => {
            const url = await Storage.get(image.key);
            return url;
          })
        );
        
        const modal = document.createElement("div");
        modal.classList.add("modal");

        const modalContent = document.createElement("div");
        modalContent.classList.add("modal-content"); 

        const modalTitle = document.createElement("h2");
        modalTitle.innerHTML = 'Name: ' + name + ' - ' + description;
        modalContent.appendChild(modalTitle);

        const modalClose = document.createElement("button");
        modalContent.classList.add("modal-content");
        modalClose.classList.add("close");
        modalClose.innerHTML = "Close &times;";

        modalClose.onclick = function () {
          document.body.removeChild(modal);
        };

        modalContent.appendChild(modalClose);

        imageUrls.forEach((url) => {
          const img = document.createElement("img");
          img.src = url;
          img.style.width = "500px";
          img.style.height = "auto"; 
          img.style.objectFit = "cover";
          modalContent.appendChild(img);
        });

        modal.appendChild(modalContent);
        document.body.appendChild(modal);
      }

const sendEmailNotificationAlbum = async () => {
  
  const AWS = require('aws-sdk');
  AWS.config.update({
    accessKeyId: 'AKIAVUYOVZCN43KLJEJQ',
    secretAccessKey: '28otiV7xZBzdtH3Smvkl+zKOBeb9nv20eiSuHDDU',
    region: 'us-west-2'
  });
  const ses = new AWS.SES({ region: 'us-west-2' }); // Specify your AWS region
  const params = {
    Destination: {
      BccAddresses: ["tim.fantuzzo7@gmail.com",
                    "jfantuz@gmail.com",
                    "stevefantuzzo@gmail.com",
                    "davidfantuzzo@gmail.com",
                    "marysfantuzzo@gmail.com",
                    "fantuzzo@gmail.com",
                    "christinanaccarati@gmail.com"]
    },
    Message: {
      Body: {
        Text: {
          Data: 'New album! Check it out at fantuzz.org/family-albums'
        }
      },
      Subject: {
        Data: 'New Album Notification'
      }
    },
    Source: 'albums@fantuzz.org'
  };

  try {
    await ses.sendEmail(params).promise();
    console.log('Email notification sent successfully');
  } catch (error) {
    console.error('Error sending email notification:', error);
    // Handle error
  }
};

  async function deleteNote({ id, name }) {
  const shouldDelete = window.confirm(`Are you sure you want to delete album ${name}?`);
  if (shouldDelete) {
    const newNotes = notes.filter((note) => note.id !== id);
    setNotes(newNotes);
    await Storage.remove(name+'_'+username+'/');
    await Storage.remove(name+'_'+username);
    await API.graphql({
      query: deleteNoteMutation,
      variables: { input: { id } },
    });
   }
  }

  async function fetchNotes() {
    const { username } = await Auth.currentAuthenticatedUser();
    const apiData = await API.graphql({
      query: listNotes,
      variables: { filter: { owner: { eq: username } } }
    });
    const notesFromAPI = apiData.data.listNotes.items;
    await Promise.all(
      notesFromAPI.map(async (note) => {
        if (note.image) {
          const url = await Storage.get(note.name+'_'+username+'/'+note.name+'_'+username);
          note.image = url;
        }
        return note;
      })
    );
    setNotes(notesFromAPI);
  }

  async function createNote(event) {
    event.preventDefault();
    const { username } = await Auth.currentAuthenticatedUser();
    console.log(username)
    const { attributes } = await Auth.currentAuthenticatedUser();
    const cognitoEmail = attributes.email;
    const form = new FormData(event.target);
    const image = form.get("image");
    const images = form.getAll("images");
    const data = {
      name: form.get("name"),
      description: form.get("description"),
      image: image.name,
      images:images,  
      owner: username,
    };
    console.log(data);
    await Storage.put(data.name+'_'+username+'/');
    if (!!data.image) await Storage.put(data.name+'_'+username+'/'+data.name+'_'+username, image);
    if (images.length > 0) {
      await Promise.all(
        Array.from(images).map(async (image) => {
          await Storage.put(data.name+'_'+username+'/'+form.get("description")+'/'+image.name, image);
        })
      );
    }
  try {
    await API.graphql({
      query: createNoteMutation,
      variables: { input: data },
    });
    await sendEmailNotificationAlbum();
    await fetchNotes(); // Wait for fetchNotes to complete before continuing
    event.target.reset(); 
  } catch (error) {
    console.error('Error creating note:', error);
  }
    fetchNotes();
    event.target.reset(); 
}


function chunk(arr, size) {
  const chunks = [];
  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }
  return chunks;
}


  return (
    <React.Fragment> <br/>
      <Heading style={{ top: "1px" }} level={5}>
        Make A New Album
      </Heading> 
      <View
        style={{ border: "solid 2px #0C647B", padding: "30px", backgroundColor:'#E4ECF2' }}
        as="form"
        margin="3rem 0"
        onSubmit={createNote}
      >
        <Flex direction={isMobile ? 'column' : 'row'} justifyContent="center">
          <Heading level={6}>Cover Photo</Heading>
          <View
            name="image"
            as="input"
            type="file"
            style={{ alignSelf: "end" }}
            accept="image/*"
            required
            placeholder="Upload an image"
          />
          <Heading level={6}>Album Photos</Heading>
          <View
            name="images"
            as="input"
            type="file"
            style={{ alignSelf: "end" }}
            accept="image/*"
            required
            multiple
          />
        </Flex>
        <br />
        <Flex direction={isMobile ? 'column' : 'row'} justifyContent="center">
          <TextField
            name="name"
            placeholder="Album Name"
            label="Album Name"
            labelHidden
            variation="quiet"
            required
          />
          <TextField
            name="description"
            placeholder="Album Description"
            label="Album Description"
            labelHidden
            variation="quiet"
            required
          />
          <Button type="submit" variation="primary">
            Create Album
          </Button>
        </Flex>
      </View>
            <Heading level={5}>Your Current Albums</Heading>
            <View style={{ border: 'solid 2px #0C647B', padding:'30px', backgroundColor:'#E4ECF2'}} margin="3rem 0">
<Flex
  direction="column"
  justifyContent="space-evenly"
  alignItems="center"
  flexwrap="wrap"
>
  {chunk(notes, isMobile ? 1 : 3).map((row) => (
    <Flex
      key={row[0].id || row[0].name}
      justifyContent="space-evenly"
      alignItems="center"
      flexWrap="wrap"
      margin="20px 0"
    >
      {row.map((note) => (
        <Flex
          key={note.id || note.name}
          direction="column"
          style={{
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
            padding: "20px",
            height: '400px',
            backgroundColor: "#fff"
          }}
          justifyContent="space-evenly"
          alignItems="center"
          margin="0 10px"
        >
          <Text as="strong" fontWeight={700}>
            {note.name}
          </Text>
          <Text as="span">{note.description}</Text>
            {note.image && (
              <div className="image-container">
                <Image src={note.image} />
              </div>
            )}
          <Button type="submit" variation="secondary" onClick={() => getImages(note)}>
            View files in Album
          </Button>
          <Button variation="link" onClick={() => deleteNote(note)}>
            Delete album
          </Button>
        </Flex>
      ))}
    </Flex>
  ))}
</Flex>
    </View>
    </React.Fragment>
  );
};

export default NewAlbumForm;

