import React, { useState, useEffect } from 'react';
import { API, Storage, Auth } from 'aws-amplify';
import { createMessage as createMessageMutation, 
         deleteMessage as deleteMessageMutation, 
         updateMessage as updateMessageMutation} from "./graphql/mutations";
import { listMessages, getMessage as getMessageQuery} from "./graphql/queries";
import { TrackingPixel } from "./trackingPixel"
import {
  Tabs,
  TabItem,
  Flex,
  Heading,
  Text,
  View,
} from '@aws-amplify/ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'; // Import react-select


const DirectMessageComponent = () => {
  const [isVisible, setIsVisible] = useState(false); // State to track visibility
  const [messages, setMessages] = useState([]); // Assuming you have messages state
  const [uname, setUname] = useState('');
  const [recipient, setRecipient] = useState('');
  const [recipient2, setRecipient2] = useState('');
  const [inputText, setInputText] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const predefinedRecipients = ["tim", "joe", "steve","dave","mary","marykay&jay","christina"];
  const predefinedRecipients2 = [
    { name: "tim", email: "tim.fantuzzo7@gmail.com" },
    { name: "joe", email: "jfantuz@gmail.com" },
    { name: "steve", email: "stevefantuzzo@gmail.com" },
    { name: "dave", email: "davidfantuzzo@gmail.com" },
    { name: "mary", email: "marysfantuzzo@gmail.com" },
    { name: "marykay&jay", email: "fantuzzo@gmail.com" },
    { name: "christina", email: "christinanaccarati@gmail.com" }
  ];
  const [inputTexts, setInputTexts] = useState({});
  const [openConversations, setOpenConversations] = useState({});

const toggleVisibility = () => {
    setIsVisible(!isVisible); // Toggle the visibility
  };

const findRecipientEmail = (recipientName) => {
  console.log('Recipient name:', recipientName);
  const recipient = predefinedRecipients2.find(recipient => recipient.name.toLowerCase() === recipientName.toLowerCase());
  console.log('Recipient:', recipient);
  return recipient ? recipient.email : null;
};

  // Function to group messages by recipient
  const groupMessagesByRecipient = (messages) => {
    const messagesByRecipient = {};
    messages.forEach((message) => {
      const recipient = message.recipient;
      if (!messagesByRecipient[recipient]) {
        messagesByRecipient[recipient] = [];
      }
      messagesByRecipient[recipient].push(message);
    });
    return messagesByRecipient;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    fetchMessages();
    const intervalId = setInterval(() => {
      fetchMessages();
    }, 500); // 5000 milliseconds = 5 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); 

  const fetchProfilePicture = async (recipientname3) => {
    try {
      const profilePictureKey = `profile_pictures/${recipientname3}.PNG`;
      const url = await Storage.get(profilePictureKey);
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };

const toggleConversation = async (conversationId, messagesInConversation) => {
  // Toggle the conversation's visibility
  setOpenConversations((prevState) => ({
    ...prevState,
    [conversationId]: !prevState[conversationId],
  }));

  // If the conversation is being opened (not closed), mark messages as read
  if (!openConversations[conversationId]) {
    try {
      // Mark each message in the conversation as read
        await Promise.all(messagesInConversation.map(message => markMessageAsRead(message.id)));
    } catch (error) {
      console.error('Error marking messages as read:', error);
      // Handle error
    }
  }
};

const fetchMessages = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    setUname(currentUser);
    const { data } = await API.graphql({ 
      query: listMessages,
      variables: {
        filter: {
          or: [
            { senderName: { eq: currentUser.username } },
            { recipient: { eq: currentUser.username } }
          ]
        }
      }
    });

    const messages = data.listMessages.items.map((message) => {
      // Check if the current user is the recipient
      const isRecipient = message.recipient === currentUser.username;
      // Set the ingoing flag accordingly
      return { ...message, ingoing: isRecipient };
    });
  
    messages.recipientProfilePictureUrl = await getRecipientProfilePicture(messages.recipient);
    setMessages(messages);
  } catch (error) {
    console.error('Error fetching messages:', error);
    // Handle error
  }
};

  const handleInputTextChange = (conversationId, newText) => {
    setInputTexts({ ...inputTexts, [conversationId]: newText });
  };
  const handleSenderSelection = (senderName) => {
    setRecipient(senderName);
  };

  const handleSenderSelection2 = (recipientname) => {
    setRecipient2(recipientname);
  };

  const sendMessage = async () => {
    const { username } = await Auth.currentAuthenticatedUser();
    const newMessage = {
      createdAt: new Date().toISOString(),   
      senderName: username,
      content: inputText,
      recipient: recipient,
      ingoing: false,
      email: findRecipientEmail(recipient),
      read: false
    };
      console.log(recipient);

      await API.graphql({
        query: createMessageMutation,
        variables: { input: newMessage },
      });

      await sendEmailNotification(newMessage);
      await fetchMessages(); 

      setInputText(''); // Clear the input field after sending the message
    };

    const handleWindowResize = () => {
      setIsMobile(window.innerWidth <= 1000); // Update the isMobile state based on window width
  };

const markMessageAsRead = async (messageId) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    console.log(currentUser.username);
    const messageData = await API.graphql({
      query: getMessageQuery, // Assuming you have a query to get a single message by ID
      variables: { id: messageId }
    });
    const message = messageData.data.getMessage; // Assuming the query returns a single message
    if (currentUser.username === message.recipient) {
      await API.graphql({
        query: updateMessageMutation,
        variables: {
          input: {
            id: messageId,
            read: true
          }
        }
      });
      await fetchMessages(); // Re-fetch messages after updating
    } else {
      console.log('Current user is not the recipient of the message.');
    }
  } catch (error) {
    console.log('Error marking message as read:', error);
  }
};

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString(); // Adjust the format as needed
}

const groupMessagesBySenderRecipient = (messages) => {
  const groupedMessages = {};
  messages.forEach((message) => {
    const sender = message.senderName;
    const recipient = message.recipient;
    const normalizedKey = [sender, recipient].sort().join('_'); // Sort sender and recipient names alphabetically
    if (!groupedMessages[normalizedKey]) {
      groupedMessages[normalizedKey] = [];
    }
    groupedMessages[normalizedKey].push(message);
  });
  return groupedMessages;
};

const sendMessage2 = async (conversationId) => {    
  const { username } = await Auth.currentAuthenticatedUser();
  const recipient = conversationId.split('_').find(name => name !== username); // Find the recipient from the conversation ID
  console.log(recipient);
  const content = inputTexts[conversationId] || ''; // Use the input text for this conversation
  const newMessage = {
    createdAt: new Date().toISOString(),   
    senderName: username,
    content: content,
    recipient: recipient,
    ingoing: false,
    email: findRecipientEmail(recipient),
    read: false

  };

  await API.graphql({
    query: createMessageMutation,
    variables: { input: newMessage },
  });

  await sendEmailNotification(newMessage);
  await fetchMessages(); 

  setInputTexts({ ...inputTexts, [conversationId]: '' }); // Clear the input field after sending the message for this conversation
};

  async function getRecipientProfilePicture(owner) {
    try {
      const recipientProfilePictureKey = `profile_pictures/${owner}.PNG`;
      const recipientProfilePictureUrl = await Storage.get(recipientProfilePictureKey);
      return recipientProfilePictureUrl;
    } catch (error) {
      console.error("Error fetching recipient's profile picture:", error);
      return null;
    }
  }



const sendEmailNotification = async (newMessage) => {
  
  const AWS = require('aws-sdk');
  AWS.config.update({
    accessKeyId: 'AKIAVUYOVZCN43KLJEJQ',
    secretAccessKey: '28otiV7xZBzdtH3Smvkl+zKOBeb9nv20eiSuHDDU',
    region: 'us-west-2'
  });
  const ses = new AWS.SES({ region: 'us-west-2' }); // Specify your AWS region
  const params = {
    Destination: {
      ToAddresses: [findRecipientEmail(newMessage.recipient)] // Recipient's email address
    },
    Message: {
      Body: {
        Text: {
          Data: `You have a new message from ${newMessage.senderName}: ${newMessage.content}`
        }
      },
      Subject: {
        Data: 'New Message Notification'
      }
    },
    Source: 'messages@fantuzz.org'
  };

  try {
    await ses.sendEmail(params).promise();
    console.log('Email notification sent successfully');
  } catch (error) {
    console.error('Error sending email notification:', error);
    // Handle error
  }
};

const renderMessagesBySenderRecipient = () => {
  const messagesBySenderRecipient = groupMessagesBySenderRecipient(messages);
  return (
    <div>
      {Object.keys(messagesBySenderRecipient).map((key) => {
        const [sender, recipient] = key.split('_');
        const conversationId = `${sender}_${recipient}`;
        const isOpen = openConversations[conversationId] || false;
        const unreadMessagesExist = messagesBySenderRecipient[key].some(message => !message.read);
        const messagesInConversation = messagesBySenderRecipient[key];
        const otherPerson = uname.username === sender ? recipient : sender;
        console.log("othperson",otherPerson);
        console.log("sender",sender);
        console.log("recip",recipient);
        console.log("uname",uname.username)
        return (
          <div key={key} style={{ marginBottom: '10px' }}>
            <h4
              className="banner1"
              style={{ color: '#000', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <span onClick={() => toggleConversation(conversationId,messagesBySenderRecipient[key])} style={{paddingLeft:'20px'}}>
                <FontAwesomeIcon style={{color: 'purple',paddingRight:'20px'}} icon={faMessage}/> 
                {otherPerson}
              </span>
                <span onClick={() => toggleConversation(conversationId,messagesBySenderRecipient[key])} style={{ marginLeft: 'auto', fontSize: isMobile ? '4em' : '2em' }}>
                  +
                </span>
            </h4>
            {isOpen && (
            <div 
              style={{     
                zIndex: 9999,                
                position: 'fixed',
                bottom: 0,
                right: isOpen ? '0.5%' : '-100%', // Slide in from the right when isOpen is true
                width: isMobile ?'100vw' : '30vw', 
                height: '50vh',
                backgroundColor: 'rgba(12, 100, 123, 0.5)',
                border: '1px solid #ccc', 
                padding: '10px', 
                borderRadius: '20px', 
                textAlign: 'left',
                transition: 'right 2s ease-in-out', // Slide animation with ease-in-out timing
                overflowY: 'auto' 
              }}
            > 
          <div
            onClick={() => toggleConversation(conversationId)}
            style={{
              cursor: 'pointer',
              position: 'sticky',
              top: '2px',
              left: '5px',
              fontSize: isMobile ? '4em' : '2em',
              fontWeight: 'bold'
            }}
          >
            <span style={{ color: 'white' }}>&mdash;</span>
          </div>             
                {messagesBySenderRecipient[key]
                  .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                  .map((message) => (
                    <div key={message.id} style={{ marginBottom: '5px', textAlign: message.ingoing ? 'right' : 'left' }}>
                      <h5 style={{ marginBottom: '5px', color : '#fff', fontWeight:'bold'}}>{formatDate(message.createdAt)}</h5>
                      <div style={{
                        display: 'inline-block',
                        maxWidth: '80%',
                        overflow: 'hidden',
                        borderRadius: '15px',
                        background: message.ingoing ? '#e6e6fa' : '#e0ffe0',
                        padding: '10px',
                        position: 'relative',
                        marginBottom: '5px',
                        borderBottomRightRadius: message.ingoing ? '0px' : '15px',
                        borderBottomLeftRadius: message.ingoing ? '15px' : '0px',
                        border: `2px solid ${message.ingoing ? 'purple' : 'green'}`, // Add colored border
                      }}>
                        <span style={{ fontWeight: 'bold', color: message.ingoing ? 'purple' : 'green' }}>{message.senderName}</span>
                        <span>: {message.content} </span>
                        {/* Add the triangle for tapering effect */}
                        <div style={{
                          position: 'absolute',
                          width: '20px',
                          height: '20px',
                          background: 'inherit',
                          bottom: '-10px',
                          zIndex: '-1',
                          transform: message.ingoing ? 'rotate(45deg) translateX(-6px)' : 'rotate(-45deg) translateX(6px)',
                          left: message.ingoing ? '-10px' : 'auto',
                          right: message.ingoing ? 'auto' : '10px',
                          borderBottom: `2px solid ${message.ingoing ? '#e6e6fa' : '#e0ffe0'}`,
                          borderRight: `2px solid ${message.ingoing ? '#e6e6fa' : '#e0ffe0'}`,
                        }} />
                      <div style = {{color:'#999'}}>
                        {!message.ingoing && (message.read ? 'Read' : 'Unread')}
                      </div>
                      </div>
                    </div>
                  ))}
                <textarea
                  value={inputTexts[conversationId] || ''} // Use input text for this conversation
                  onChange={(e) => handleInputTextChange(conversationId, e.target.value)}
                  placeholder="Reply!"
                  style={{ width: '100%', marginBottom: '10px', padding: '5px', borderRadius: '5px', minHeight: '40px' }}
                />
                <button onClick={() => sendMessage2(conversationId)} style={{ width: '200px', height: '50px', padding: '10px 20px', fontWeight: 'bold', borderRadius: '30px', background: '#fff', color: '#000', border: 'none', cursor: 'pointer' }}>Send Reply</button>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};


return (
  <View> 
  <h3>Direct Messages</h3>
    <Flex direction={isMobile ? 'column' : 'row'} style={{ width: '100%'}} justifyContent="flex-start" alignItems="flex-start">
      {/* Existing messages section */}
      <Flex direction="column" alignItems="center" style={{ flex: '80%' , height:'auto' , border: "solid 2px #0C647B", backgroundColor:'#E4ECF2' }}>
    <Flex direction="column" alignItems="center">
    <h3 
      style={{color: '#000', cursor: 'pointer', border:'none'}} 
      onClick={toggleVisibility}
    >
      {isVisible ? '− New Conversation ' : '+ New Conversation'}
    </h3>
      
      {isVisible && ( // Show this content only if isVisible is true
        <Flex style={{marginLeft: '5px'}} direction="column" alignItems="center" justifyContent="center">
          <select
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            style={{ width: '150px', marginBottom: '10px', padding: '5px', borderRadius: '5px', minHeight: '40px' }}
          >
            <option value="">Select Recipient</option>
            {predefinedRecipients.map((recipient) => (
              <option key={recipient} value={recipient}>{recipient}</option>
            ))}
          </select>
          <textarea
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Type your message here..."
            style={{ width: '500px', marginBottom: '10px', padding: '5px', borderRadius: '5px', minHeight: '40px' }}
          />
          <button onClick={sendMessage} style={{ width: '100px', height: '50px', padding: '10px 20px', borderRadius: '30px', background: '#0C647B', color: '#fff', border: 'none', cursor: 'pointer' }}>
            Send
          </button>
        </Flex>
      )}
    </Flex>
        {renderMessagesBySenderRecipient()}
      </Flex>
    </Flex>
  </View>
 );
};

export default DirectMessageComponent;
