import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { Tabs, TabItem, Heading, View, withAuthenticator } from "@aws-amplify/ui-react";
import NavBar from "./NavBar";
import NewAlbumForm from "./NewAlbumForm";
import Exchange from "./Exchange";
import { API, Auth } from "aws-amplify";
import PayPalWF from "./PayPalWF";
import awsconfig from "./aws-exports";
import DirectMessageComponent from "./msgPane2"
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Sidebar from "./SideBar"
import Homepage from "./Home"

const App = ({ signOut }) => {
  const [isPaid, setIsPaid] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [username, setUsername] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    getUsername();
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
      setIsCollapsed(true);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function getUsername() {
    const { username } = await Auth.currentAuthenticatedUser();
    setUsername(username);
  }

  return (
    <BrowserRouter>
      <div className={`App ${isMobile ? "mobile" : ""}`}>
        {!isMobile && <NavBar signOut={signOut} isMobile={isMobile} toggleCollapse={toggleCollapse} />}
        <Sidebar isCollapsed={isCollapsed} toggleCollapse={toggleCollapse} />
        <div className={`content ${isCollapsed ? "collapsed1" : "expanded1"}`}>
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route path="/inbox" element={<DirectMessageComponent />} />
            <Route path="/my-albums" element={<NewAlbumForm />} />
            <Route path="/family-albums" element={<Exchange />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};


export default withAuthenticator(App);
